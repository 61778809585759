export default defineI18nConfig(async () => {
  const locale = useRuntimeConfig().public.locale.substring(0, 2);

  return {
    legacy: false,
    locale,
    warnHtmlMessage: false, // disable warning HTML in message
    messages: {
      [locale]: await import(`./locales/${locale}.json`),
    },
  };
});
